<template>
  <div class="row">
    <div
      class="flex xs12"
      v-show="!hideTabSelector"
    >
      <va-tabs
        v-model="tabValue"
        style="width: 100%; min-width: 250px;"
        grow
      >
        <va-tab
          v-for="title in tabTitles"
          :key="title"
        >
          {{title}}
        </va-tab>
      </va-tabs>
    </div>
    <div class="flex xs12">
      <div
        v-for="name in slotNames"
        :key="name"
        v-show="isActive(name)"
      >
        <slot :name="name" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabs-container',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    hideSingle: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      tabValue: 0,
    }
  },
  watch: {
    tabValue (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onChange()
      }
    },
  },
  computed: {
    tabTitles () {
      return this.tabs.map(t => t.title)
    },
    slotNames () {
      return this.tabs.map(t => t.name)
    },
    hideTabSelector () {
      return this.tabs.length <= 1 && this.hideSingle
    },
  },
  methods: {
    onChange () {
      const tab = this.tabs[this.tabValue]
      this.$emit('change', tab)
      if (tab.change) {
        tab.change()
      }
    },
    isActive (name) {
      return this.tabs.findIndex(t => t.name === name) === this.tabValue
    },
  },
}
</script>

<style>
</style>
